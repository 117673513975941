import { Component, OnInit, NgZone,SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute, Routes, ActivatedRouteSnapshot,RoutesRecognized,NavigationEnd,NavigationStart,Params } from '@angular/router';
import { RestService } from '../rest.service';
import { AppVariables } from '../app.constants';
import { NgbAccordionConfig, NgbModalConfig, NgbModal} from '@ng-bootstrap/ng-bootstrap';


import { FormGroup, AbstractControl, FormBuilder, Validators,FormControl } from '@angular/forms';
import  QRCode  from 'qrcode'
import { ToasterContainerComponent, ToasterService } from 'angular2-toaster';
import async from 'async'
import sha1 from "sha1"
import * as moment  from 'moment'

declare var $:any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [NgbAccordionConfig,NgbModalConfig, NgbModal]

})


export class DashboardComponent implements OnInit {
  public upcomingForm:FormGroup;
  public upcomingEvents : any;
  public enableFilterAndSort : boolean = false;
  public registeredEvents : any;
  public attendedEvents : any;
  public sliderData : any;
  public selectedEventData : any;
  public qrCode : any;
  public searchText : any;
  public closeIcon : boolean = false;
  public p: number = 1;
  public r: number=1;
  public itemPerPage : number = AppVariables.item_per_page;
  public  categoriesData : any;
  public  activeTab : any;
  public fullUpcomingData: any;
  public fullRegistrationData: any;
  public fullAttendedData: any;
  dropdownSettings = {};
  public dropdownList:any = [];
  selectedItems = [];
  categoryArr = [];
  public registrationclosed: boolean = false
  public nextsessiondate:any;
  public Signature:any;
  public sortvalue:any = "date";
  public lat:number = 12.958499999999999;
  public lng:number = 77.65298059999999;
  public zoom:number = 16;
  public regnextsessiondate:any;
  public code = AppVariables.Ipay88code;
  public key = AppVariables.Ipay88key
  public responsePage = AppVariables.responsePage
  public backendUrl = AppVariables.backendUrl


  constructor( public router:Router,public restApi:RestService,private activatedRoute: ActivatedRoute,private toasterService: ToasterService,private formBuilder: FormBuilder, private zone:NgZone, private modalService: NgbModal) {
   
    this.toasterService = toasterService;
    this.searchText = ""
    this.getEventCategory().then(response=>{
      this.dropdownList = response
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        allowSearchFilter: false
      };
    })
    
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.activeTab = params['string'];
      console.log("this.activeTab::",this.activeTab)
      if(this.activeTab == 'Upcoming'){

         let eventMasterCode = localStorage.getItem("eventMasterCode")
        if(eventMasterCode){
          this.router.navigateByUrl('event/'+eventMasterCode)
          
        }else{
          this.getUpcomingEvents()
        }
      }else if(this.activeTab == 'Registered'){
        this.getRegisteredEvents();
      }
      else if(this.activeTab == 'Attended'){
        this.getAttendedEvents();
      }else {
        let eventMasterCode = localStorage.getItem("eventMasterCode")
        if(eventMasterCode){
          this.router.navigateByUrl('event/'+eventMasterCode);
        }else{
          this.getUpcomingEvents();
        }
        
      }
    });
    
  }

  distancia(){
    //it's item.Latitud & item.Longitud, from the data
   
    console.log("enableeee location")
    navigator.geolocation.getCurrentPosition((position) => { 
      let lat = position.coords.latitude
      let lon = position.coords.longitude
      let that = this;
      console.log("position:::",lat,lon)
      async.eachSeries(this.upcomingEvents,function(eachEventmaster,next){

        async.eachSeries(eachEventmaster.events,function(eachevent,eventnext){
          

          eachevent["distance"] = that.getDistanceFromLatLonInKm(lat,lon,parseFloat(eachevent.latitude),parseFloat(eachevent.longitude)).toFixed(1)
          eventnext()

        },function(){
          eachEventmaster.events = eachEventmaster.events.sort(function(a,b) {
              return a.distance - b.distance;

            });
            eachEventmaster["amoung_event_nearest"] = eachEventmaster.events[0].distance 
            next()          
        })       
      },function(){

        that.upcomingEvents.sort(function(a,b) {
          return a.amoung_event_nearest - b.amoung_event_nearest;
         
       });

          
      })
     
      //return parseFloat(this.getDistanceFromLatLonInKm(lat,lon,item.,item.Longitud).toFixed(1));
  });
  }

  getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
    var R = 6878; // Radius of the earth in km
    var dLat = this.deg2rad(lat2-lat1);  // this.deg2rad below
    var dLon = this.deg2rad(lon2-lon1); 
    var a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
      ; 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c; // Distance in km
    return d;
  };
  
  deg2rad(deg) {
    return deg * (Math.PI/180)
  }

  gotoDashboard(){
    this.router.navigateByUrl('dashboard/Upcoming');

  }

  ngAfterViewInit(){
    console.log("localStorage:::",localStorage.getItem("activeTab"))
  }

  onItemSelect(item: any) {
    if(this.categoryArr.includes(item) == false){
      this.categoryArr.push(item);
    };
    this.getUpcomingEvents();
  }

  onSelectAll(items: any) {
    this.categoryArr = items;
    this.getUpcomingEvents();
  }

  onDeSelectAll() {
    this.categoryArr = [];
    this.getUpcomingEvents();
  }

  onItemDeSelect(item: any){
    this.categoryArr = this.categoryArr.filter(function(eachcat){
      return eachcat.item_id != item.item_id
    })
    this.getUpcomingEvents();
  }

  logout(){
    var url = '#/dashboard/';
    this.restApi.externalLogoutRedirect(url);
    

  }

  sortValue(){
    if(this.sortvalue == "date"){
      this.getUpcomingEvents();

    }else{
      this.distancia()
    }
    


  }

  getNextSessiondate(eachUpcomingEvent){
    

    let that =  this
    //---- Check if one event is open for registration as soon as we found break it --------- //
    let nextsessiondate = new Date(moment().format('YYYY-MM-DD'))//
    let now = new Date(moment().format('YYYY-MM-DD'))
    let flag = true
    async.eachSeries(eachUpcomingEvent.events,function(eachevent,next){
      async.eachSeries(eachevent.event_sessions,function(eachsession,nextsession){

        let sessionDate = new Date(eachsession.event_date)
        if(sessionDate > nextsessiondate && flag == true){
          flag = false
          nextsessiondate = sessionDate
          nextsession()
        }else{
          if(sessionDate < nextsessiondate && sessionDate > now  ){
              nextsessiondate = sessionDate
          }
          nextsession()
        }

      },function(){
        next()
      })
      

    },function(){
      that.nextsessiondate = nextsessiondate
    })

    return that.nextsessiondate

  }

  getregistrationclosed(events){
    let that =  this
    //---- Check if one event is open for registration as soon as we found break it --------- //
    let registrationclosedFlag = true
    async.eachSeries(events,function(eachevent,next){
      let registrationclosed = eachevent.event_sessions.findIndex(function(x){
        let sessionDate = new Date(x.event_date)
        let now = new Date()
        return sessionDate < now

      });
      if(registrationclosed >= 0 || eachevent.event_closed == true){
        //registrationclosedFlag = true
        next()
        
      }else{
        registrationclosedFlag = false
        next()

      }

    },function(){
      that.registrationclosed = registrationclosedFlag
    })

    return that.registrationclosed
   
     

    


 

  }

  getregistreddate(eachevent){
    let that =  this
    let nextsessiondate = new Date(moment().format('YYYY-MM-DD'))
    let now = new Date(moment().format('YYYY-MM-DD'))
    let flag = true
    async.eachSeries(eachevent.event_details.event_sessions,function(eachsession,nextsession){

      let sessionDate = new Date(eachsession.event_date)
      if(sessionDate > nextsessiondate && flag == true){
        flag = false
        nextsessiondate = sessionDate
        nextsession()
      }else{
        if(sessionDate < nextsessiondate && sessionDate > now  ){
            nextsessiondate = sessionDate
        }else{
          nextsessiondate = sessionDate
        }
        nextsession()
      }

    },function(){
      that.regnextsessiondate = nextsessiondate
    })
    
    return that.regnextsessiondate

    
  }
  changecss(){
    console.log("in change css")
  }

  getamount(eachRegisteredEvent){
    let registreduser = (eachRegisteredEvent.no_of_adult_pax_registered + eachRegisteredEvent.no_of_child_pax_registered) == 0 ? 1 : (eachRegisteredEvent.no_of_adult_pax_registered + eachRegisteredEvent.no_of_child_pax_registered)
    let amount = (registreduser * eachRegisteredEvent.event_details.event_price).toFixed(2).toString()
    return amount
  }

  genarateSignature(eachRegisteredEvent){
    let refNo = eachRegisteredEvent.code
    let registreduser = (eachRegisteredEvent.no_of_adult_pax_registered + eachRegisteredEvent.no_of_child_pax_registered) == 0 ? 1 : (eachRegisteredEvent.no_of_adult_pax_registered + eachRegisteredEvent.no_of_child_pax_registered)
    let amount = (registreduser * eachRegisteredEvent.event_details.event_price).toFixed(2).replace(".","").toString()
    let that = this
    console.log("amount::",amount)   
      let value = that.key+that.code+refNo+""+amount+"MYR";
      let hex = sha1(value), 
      bytes = [],
      str;
  
      for(var i=0; i< hex.length-1; i+=2){
          bytes.push(parseInt(hex.substr(i, 2), 16));
      }
  
      str = String.fromCharCode.apply(String, bytes);
      that.Signature = btoa(str)
      eachRegisteredEvent.getSignature = true
      eachRegisteredEvent.signature = that.Signature
      return that.Signature
      
    

  }

  showPaynow(eachRegisteredEvent){
    let registrationclosed = eachRegisteredEvent.event_details.event_sessions.findIndex(function(x){
      let sessionDate = new Date(x.event_date)
      let now = new Date(moment().format('YYYY-MM-DD'))
      return sessionDate < now

    });
    if(registrationclosed >= 0){
      return false

    }else{
     
      return true
    }
  }

  paynow(registration_code){

    $("#paymentId"+registration_code).submit();
      // setTimeout(function(){
      //  
      // }, 3000)
    

    
  }

  getFirstName(eachAttendata){
    let index = eachAttendata.paxInformation.findIndex((eachpax)=>{
      return eachpax.is_primary_registrant == true

    })

    if(index == -1){
      eachAttendata.getFirstName = true
      eachAttendata.firstname = eachAttendata.paxInformation[0].first_name
      return eachAttendata.paxInformation[0].first_name

    }else{
      eachAttendata.getFirstName = true
      eachAttendata.firstname = eachAttendata.paxInformation[index].first_name
      return eachAttendata.paxInformation[index].first_name
    }



  }

  getMobileNumber(eachAttendata){
    let index = eachAttendata.paxInformation.findIndex((eachpax)=>{
      return eachpax.is_primary_registrant == true

    })

    if(index == -1){
      eachAttendata.getMobile = true
      eachAttendata.mobile = eachAttendata.paxInformation[0].mobile
      return eachAttendata.paxInformation[0].mobile

    }else{
      eachAttendata.getMobile = true
      eachAttendata.mobile = eachAttendata.paxInformation[index].mobile
      return eachAttendata.paxInformation[index].mobile
    }

  }

  getEmail(eachAttendata){
    let index = eachAttendata.paxInformation.findIndex((eachpax)=>{
      return eachpax.is_primary_registrant == true

    })

    if(index == -1){
      eachAttendata.getEmail = true
      eachAttendata.email = eachAttendata.paxInformation[0].email
      return eachAttendata.paxInformation[0].email

    }else{
      eachAttendata.getEmail = true
      eachAttendata.email = eachAttendata.paxInformation[index].email
      return eachAttendata.paxInformation[index].email
    }

  }

  getSomeOneelseName(eachAttendata){
    let index = eachAttendata.paxInformation.findIndex((eachpax)=>{
      return eachpax.is_primary_registrant == true

    })

    if(index == -1){
      return eachAttendata.paxInformation[0].first_name

    }else{
      return eachAttendata.paxInformation[index].first_name
    }

  }


  generateQRToken(ticketId,code){
    QRCode.toDataURL(JSON.stringify({ ticketId: ticketId,eventcode: code})).then(url => {
      this.qrCode = url;
    }).catch(err => {
      //console.error(err)
    })

  }

  renderEventData(eventData){
    this.selectedEventData = eventData;
    console.log("this.selectedEventData:::",this.selectedEventData)
    this.lat = parseFloat(this.selectedEventData.event_details.latitude)
    this.lng = parseFloat(this.selectedEventData.event_details.longitude)
    console.log(this.lat,this.lng)
    this.generateQRToken(this.selectedEventData.code,this.selectedEventData.event_details.code);

  }

  gotoDetail(eventCode){
    this.router.navigateByUrl('/event/'+eventCode);
  }

  checkLogin(){
    if(this.restApi.isTokenExists== false){
      var url = '#/dashboard/';
      this.restApi.externalRedirect(url);
      
    }
  }

  getImage(eventData){
    let eventObj = eventData.event_master_images.find(x => x.is_image === true)
    return eventObj ? eventObj.src : '../../assets/images/placeholder_card.png';
  }

  getUpcomingEvents(){
    this.activeTab = 'Upcoming';
    localStorage.setItem("activeTab",this.activeTab)
    let filteredObj = {};
    var selectedCategoriesArr;
    if(this.categoryArr.length > 0){
      selectedCategoriesArr = this.categoryArr.map(function (el) { return el.item_id; });
    }else {
      selectedCategoriesArr = [];
    }
    filteredObj['filters'] = selectedCategoriesArr;
    this.enableFilterAndSort = true;
    this.restApi.post(AppVariables.upcomingEventsUrl, filteredObj,(status,data)=>{
       if(status == true){
         if(data.status == 'success' &&  data.result.code == 200){
             this.upcomingEvents = data.result.data;
             this.fullUpcomingData = data.result.data;
             this.showCloseIcon()
             //this.toasterService.clear();
             //this.toasterService.pop('success', data.result.code, data.result.message);
          }else {
            this.toasterService.clear();
            this.toasterService.pop('error', data.result.code, data.result.message);
          }
       }else {
         this.toasterService.clear();
         this.toasterService.pop('error', "Upcoming Events", "Something went wrong");
       }
    });
  }

  getRegisteredEvents(){
    this.activeTab = 'Registered';
    localStorage.setItem("activeTab",this.activeTab)
    this.checkLogin();
    this.enableFilterAndSort = false;
    this.restApi.get(AppVariables.getEvents,(status,data)=>{
      if(status == true){
        if(data.status == 'success' &&  data.result.code == 200){
          this.registeredEvents = data.result.data;
          this.fullRegistrationData = data.result.data;
          this.showCloseIcon()
          //this.toasterService.clear();
          //this.toasterService.pop('success', data.result.code, data.result.message);
       }else {
         this.toasterService.clear();
         this.toasterService.pop('error', data.result.code, data.result.message);
       }
      }else {
        this.toasterService.clear();
        this.toasterService.pop('error', "Upcoming Events", "Something went wrong");
      }
    })
  }

  getAttendedEvents(){
    this.activeTab = 'Attended';
    localStorage.setItem("activeTab",this.activeTab)
    this.checkLogin();
    this.enableFilterAndSort = false;
    this.restApi.get(AppVariables.getAttendedEvents,(status,data)=>{
      if(status == true){
        if(data.status == 'success' &&  data.result.code == 200){
          this.attendedEvents = data.result.data;
          this.fullAttendedData = data.result.data;
          this.showCloseIcon()
          //this.toasterService.clear();
          //this.toasterService.pop('success', data.result.code, data.result.message);
       }else {
         this.toasterService.clear();
         this.toasterService.pop('error', data.result.code, data.result.message);
       }
      }else {
        this.toasterService.clear();
        this.toasterService.pop('error', "Upcoming Events", "Something went wrong");
      }
    })
  }

  resetSearch(){
    this.searchText = "";
    this.closeIcon = false;

  }

  showCloseIcon(){
    console.log("this.searchText::::",this.searchText)
    if(this.searchText && this.searchText.length > 0){      
      if(this.fullAttendedData){
        let searchText = this.searchText
        this.attendedEvents = this.fullAttendedData.filter(function(eachAttendata){
          return (eachAttendata.event_details.event_master.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1 || eachAttendata.event_details.location_name.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
        })

      }
      if(this.fullRegistrationData){
        let searchText = this.searchText
        console.log("this.fullRegistrationData::",this.fullRegistrationData)
        this.registeredEvents = this.fullRegistrationData.filter(function(eachregdata){
          console.log("eachdata.event_details.event_master.title::",eachregdata.event_details.event_master.title)
          return (eachregdata.event_details.event_master.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1 || eachregdata.event_details.location_name.toLowerCase().indexOf(searchText.toLowerCase()) > -1)

        })

      }
      if(this.fullUpcomingData){
        let searchText = this.searchText
        this.upcomingEvents = this.fullUpcomingData.filter(function(eachdata){
          return eachdata.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1 || (eachdata.events.findIndex((event)=>{
            return event.location_name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
          })) > -1
        })

      }
      
      
      this.closeIcon = true;
    }else {
      this.upcomingEvents = this.fullUpcomingData
      this.registeredEvents = this.fullRegistrationData
      this.attendedEvents = this.fullAttendedData
      this.closeIcon = false;
    }
  }


 getEventCategory(){
    return new Promise((resolve, reject)=>{
        this.restApi.get(AppVariables.getCategoriesUrl,(status,data)=>{
          let categoryList = [];
          if(status == true){
            if(data.status == 'success' ){ //&&  data.result.code == 200
              this.categoriesData = data.result.data;
                for(var i =0; i< this.categoriesData.length; i++){
                  categoryList.push({item_id :this.categoriesData[i].code, item_text: this.categoriesData[i].category})
                }
                resolve (categoryList);
          }else {
            this.toasterService.clear();
            this.toasterService.pop('error', data.result.code, data.result.message);
            reject(null)
          }
          }else {
            this.toasterService.clear();
            this.toasterService.pop('error', "Upcoming Events", "Something went wrong");
            reject(null) 
          }
        });
    })
  }
  open(content) {
    this.modalService.open(content);
  }
}
