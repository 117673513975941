import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map, catchError, tap } from "rxjs/operators";
import * as jwe from "node-webtokens";
import NodeRSA from "node-rsa";
import { CookieService } from "ngx-cookie-service";

import { AppVariables } from "./app.constants";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class RestService {
  customerNumber: any;
  loggedInCustomerDetails: any;
  isTokenExists: Boolean;

  constructor(private http: HttpClient, private cookieService: CookieService) {
    const key = new NodeRSA(AppVariables.privateKey);
    const private_key = key.exportKey("pkcs1-private-pem");
    var token = this.cookieService.get(AppVariables.auth_cookie_name);
    var that = this;
    if (token) {
      this.isTokenExists = true;
      let decodedData = jwe.parse(token).verify(private_key);
      if (!decodedData.valid) {
        console.log("Token is not valid");
        //-----------Should call refresh token ---------- //
        let url = "#/dashboard/";
        let session_id = this.cookieService.get(
          AppVariables.auth_cookie_session_id
        );
        let target_client_code = AppVariables.client_code;
        let client_code = this.cookieService.get(
          AppVariables.auth_cookie_client_code
        );
        //window.location.href =  'http://caringconnect.spurtreetech.com/#/confirmation/?session_id='+session_id+'&token='+token+'&target_client_code='+target_client_code+'&is_staff=0&client_code='+client_code+'&redirect_url='+AppVariables.redirectUrl+url
        window.location.href =
          "https://connect.caring2u.com/#/confirmation/?session_id=" +
          session_id +
          "&token=" +
          token +
          "&target_client_code=" +
          target_client_code +
          "&is_staff=0&client_code=" +
          client_code +
          "&redirect_url=" +
          AppVariables.redirectUrl +
          url;
      } else {
        let decodedPayload = decodedData.payload;
        this.customerNumber = decodedPayload.phonenumber;
        httpOptions.headers = httpOptions.headers.append("x-user-token", token);
        httpOptions.headers = httpOptions.headers.append(
          "client_code",
          AppVariables.client_code
        );
      }
    } else {
      this.isTokenExists = false;
    }
  }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  //GET Method without headers
  get(url, callback) {
    return this.http.get(url, httpOptions).subscribe(
      (res) => {
        console.log(res);
        callback(true, res);
      },
      (err) => {
        console.log("Toast gone");
      }
    );
  }

  //PUT Method witout headers
  put(url, data, callback) {
    return this.http.put(url, JSON.stringify(data), httpOptions).subscribe(
      (res) => {
        console.log(res);
        callback(true, res);
      },
      (err) => {
        console.log("Toast gone");
      }
    );
  }

  //POST witout headers
  post(url, data, callback) {
    return this.http.post(url, data, httpOptions).subscribe(
      (res) => {
        console.log(res);
        callback(true, res);
      },
      (err) => {
        console.log(err);
        callback(false, err);
      }
    );
  }

  delete(url, callback) {
    return this.http.delete(url, httpOptions).subscribe(
      (res) => {
        console.log(res);
        callback(true, res);
      },
      (err) => {
        console.log("Toast gone");
      }
    );
  }

  getCustomer(callback) {
    this.get(AppVariables.getCustomerUrl, function (status, data) {
      if (data.result.response.status.code == 200) {
        console.log(
          "=====data.result.response.customers.customer[0]",
          data.result.response.customers.customer[0]
        );
        callback(data.result.response.customers.customer[0]);
      }
    });
  }

  externalRedirect(url) {
    // window.location.href =  'http://caringconnect.spurtreetech.com/#/?is_staff=0&client_code=ZhtsQLBxA3yy&redirect_url='+AppVariables.redirectUrl+url

    window.location.href =
      "https://connect.caring2u.com/#/?is_staff=0&client_code=ZhtsQLBxA3yy&redirect_url=" +
      AppVariables.redirectUrl +
      url;
  }

  externalLogoutRedirect(url) {
    //window.location.href =  'http://caringconnect.spurtreetech.com/#/logout?redirect_url='+AppVariables.redirectUrl+url
    window.location.href =
      "https://connect.caring2u.com/#/logout?redirect_url=" +
      AppVariables.redirectUrl +
      url;
  }
}
