//Modules
import { ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

//Component Pages
import { DashboardComponent } from './dashboard/dashboard.component';
import { EventDetailsComponent } from './event-details/event-details.component';
import { PaymentGatewayComponent } from './payment-gateway/payment-gateway.component'



//Router Links

export const router: Routes = [
  { path: '', redirectTo: 'dashboard/Upcoming', pathMatch: 'full'},
  { path: 'dashboard/:string', component: DashboardComponent},
  { path: 'event/:string', component: EventDetailsComponent},
  { path: 'paymentgateway', component:PaymentGatewayComponent },

]
export const routes: ModuleWithProviders = RouterModule.forRoot(router,{useHash:true});
