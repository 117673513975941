import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-payment-gateway',
  templateUrl: './payment-gateway.component.html',
  styleUrls: ['./payment-gateway.component.css']
})



export class PaymentGatewayComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    $('#paymentId').submit();
  
  }


}
