import { Component, OnInit } from '@angular/core';
import { NgbAccordionConfig, NgbModalConfig, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { AppVariables } from '../app.constants';
import { Router, ActivatedRoute, Routes, ActivatedRouteSnapshot,RoutesRecognized,NavigationEnd,NavigationStart,Params } from '@angular/router';
import { FormGroup, AbstractControl, FormBuilder, Validators,FormControl } from '@angular/forms';
import { ToasterContainerComponent, ToasterService } from 'angular2-toaster';
import { RestService } from '../rest.service';
import { CarouselModule } from 'ngx-bootstrap';
import { CarouselConfig } from 'ngx-bootstrap/carousel';


import async from 'async'
import { concat } from 'rxjs/internal/observable/concat';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import randomstring from "randomstring"
import sha1 from "sha1"
import * as moment  from 'moment'



declare var $:any;
@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.css'],
  providers: [NgbAccordionConfig,NgbModalConfig, NgbModal, { provide: CarouselConfig, useValue: { interval: 2000, noPause: true, showIndicators: true } }]


})
export class EventDetailsComponent implements OnInit {

  public firstSlideform:FormGroup;
  public userform:FormGroup;
  public firstname : AbstractControl;
  public lastname : AbstractControl;
  public mobileNumber : AbstractControl;
  public email :AbstractControl;
  public countrycode :any = "60";
  public numberOfAdults :number = 1
  public numberOfChildren :number = 0
  public eventMasterCode : any;
  public eventsData : any;
  public selectedEvent : any;
  public lastSlide : Boolean = false;
  public firstSlide : Boolean = true;
  public slideIndex: number = 1;
  public maxSlide : number = 4;
  public formValid : boolean = true;
  public enableUserForm : boolean = false;
  public enableSomeElse : boolean = false;
  public enableEmail : boolean = false;
  public enableMobile : boolean = false;
  public downloadFileData : any;
  public sliderData : any;
  public organisersData : any;
  public registrationclosed:boolean = true;
  public is_paid_event: boolean = false;
  public loggedInUserData:any;
  public Signature: any;
  public refNo:any;
  public nextsessiondate:any;
  public total_pax_allowed_now:any = 0;
  public code = AppVariables.Ipay88code;
  public key = AppVariables.Ipay88key
  public responsePage = AppVariables.responsePage
  public backendUrl = AppVariables.backendUrl


  constructor(private http: HttpClient,config: NgbAccordionConfig,public router:Router,private activatedRoute: ActivatedRoute,public restApi:RestService,public _router:Router,private formBuilder: FormBuilder,private toasterService: ToasterService, private modalService: NgbModal ) {
    config.closeOthers = true;
    config.type = 'info';
    this.toasterService = toasterService;
   
    

    var obj = {
      firstname : new FormControl('',Validators.required),
      lastname : new FormControl(''),
      mobileNumber : new FormControl('', [Validators.required, Validators.minLength(8)]),
      email : new FormControl('')
    }
  
    this.userform = new FormGroup(obj);
  }

  

  ngOnInit() {
    var that = this;
    //--------- auto moving -------- //

    this.activatedRoute.params.subscribe((params: Params) => {
        that.eventMasterCode = params['string'];
        that.restApi.get(AppVariables.eachEventUrl+that.eventMasterCode,function(status,data){
          if(status == true){
             if(data.status='success'){
               that.eventsData = data.result.data[0];
               if(that.eventsData.event_master_images){
                  that.downloadFileData=that.eventsData.event_master_images.filter(x => x.is_image == false);
                  that.sliderData = that.eventsData.event_master_images.filter(x => x.is_image == true);
               }
               let registrationclosedFlag = true
               async.eachSeries(that.eventsData.events,function(eachevent,next){
                let registrationclosed = eachevent.event_sessions.findIndex(function(x){
                  let sessionDate = new Date(x.event_date)
                  let now = new Date()
                  return sessionDate < now
          
                });
                if(registrationclosed >= 0 || eachevent.event_closed == true || eachevent.pax_limit_reached == true){
                  next()
                  
                }else{
                  registrationclosedFlag = false
                 
                   next()
          
                }
          
              },function(){
                that.registrationclosed = registrationclosedFlag
              })
               
             }
          }
        });
    });

    let eventMasterCode = localStorage.getItem("eventMasterCode")
    if(eventMasterCode){
      localStorage.setItem("eventMasterCode","")
      if(this.restApi.isTokenExists== true){
        this.formValid = false;
        setTimeout(() => {
          $("#registerModal").modal({ backdrop: 'static' }, 'show');
          
        }, 1000);
        
      }else{
        localStorage.setItem("eventMasterCode","")
        console.log("pass")
      }
      
    }else{
      localStorage.setItem("eventMasterCode","")
      console.log("pass")
    }
    

    

  ; 

    /* Form Validation*/
    this.resetFirstSlideform()
    
  }

  getNextSessiondate(eachUpcomingEvent){
    

    let that =  this
    //---- Check if one event is open for registration as soon as we found break it --------- //
    let nextsessiondate = new Date(moment().format('YYYY-MM-DD'))//
    let now = new Date(moment().format('YYYY-MM-DD'))
    let flag = true
    async.eachSeries(eachUpcomingEvent.events,function(eachevent,next){
      async.eachSeries(eachevent.event_sessions,function(eachsession,nextsession){
        let sessionDate = new Date(eachsession.event_date)
        if(sessionDate > nextsessiondate && flag == true){
          flag = false
          nextsessiondate = sessionDate
          nextsession()
        }else{
          if(sessionDate < nextsessiondate && sessionDate > now  ){
              nextsessiondate = sessionDate
          }
          nextsession()
        }

      },function(){
        next()
      })
      

    },function(){
      that.nextsessiondate = nextsessiondate
    })

    return that.nextsessiondate

  }

  ngOnDestroy(){
    $("#registerModal").modal('hide');
    $("#downloadFilesMdl").modal('hide');

     
  }

  resetFirstSlideform(){
    this.firstSlideform = this.formBuilder.group({
      location: ['', [Validators.required]],
    });
  }

  gotoDashboard(){
    this.router.navigateByUrl('dashboard/Upcoming');

  }

  successNotify(){
    console.log("in successsss")
  }

  failedNotify(){
    console.log("in failedddddd")
  }

  cancelNotify(){
    console.log("in cancelllll")
  }

  genarateSignature(refNo,amount){
    let that = this
    return new Promise(function(resolve, reject) {
      that.refNo = refNo;
      let value = that.key+that.code+that.refNo+""+amount+"MYR";
      let hex = sha1(value), 
      bytes = [],
      str;
  
      for(var i=0; i< hex.length-1; i+=2){
          bytes.push(parseInt(hex.substr(i, 2), 16));
      }
  
      str = String.fromCharCode.apply(String, bytes);
      that.Signature = btoa(str)
      resolve()
      
    });

  }

  ipay88(){

    //this.genarateSignature().then(function(){
      $("#paymentId").submit();

    //})
    
    

  }

 

  checkeventClose(eachevent){
    let closed = eachevent.event_sessions.findIndex(function(x){
      let sessionDate = new Date(x.event_date)
      let now = new Date()
      return sessionDate < now

    });
    if(closed == -1 && eachevent.event_closed == false && eachevent.pax_limit_reached == false){

      return false
    }else{
      return true
    }

  }


  closemodal(){
    $("#myModal1").modal('hide');
  }

  redirecttoRegistred(){
    $("#myModal1").modal('hide');

    this._router.navigateByUrl('/dashboard/Registered');

  }





  nextSlide(){
    
    if(!this.lastSlide){
      this.slideIndex++;
    }
    this.enableUserForm = false;
    if(this.lastSlide){
      //------- If event is paid one then call ipay function ---------- //
      let registerUserData = new Object()
      registerUserData["event_code"] = this.selectedEvent.code
      let userData  =  this.userform.value;
      let mobile = userData.mobileNumber.toString()
      if(this.enableSomeElse){
        mobile = this.countrycode+userData.mobileNumber.toString()

      }
      registerUserData["phonenumber"] = mobile
      this.restApi.post(AppVariables.checkeventRegistered,registerUserData,(status,data)=>{
        console.log("data:::::",data,status)
        if(data.result.code == 200){
          console.log("dataaaa",data.result.code)
          
            this.registerUserForEvent();
    
          
  
  
            return 0
           }else {
             //--------- Show pop up-------- //
             this.registerModalClose()
             $("#myModal1").modal('show');
 
      
           // this.toasterService.pop('error','Registered Events',  'Event is already registered!!');

           }
        
      });
        

     
      
      
    }
    
    if(this.slideIndex > 1){
      this.firstSlide = false;
    }

    if((this.slideIndex - 1) == 2){
      console.log("validate adult")
      //this.formValid = ;


    }
    
    if(this.slideIndex == 3){
      if(this.numberOfAdults > 0){
        this.enableUserForm = true;

      }else{
        this.slideIndex--;
        this.toasterService.pop('error','',  'Enter Atleast 1 Participant! ');

      }
      
      
    }
    if(this.slideIndex >= this.maxSlide){
      this.lastSlide = true;
      this.firstSlide = false;
    }

    if(this.enableUserForm){
      this.getRegisterEventInfo();
      this.validateUpdateForm();
    }
  }

  previousSlide(){
    this.slideIndex--;
    if(this.lastSlide && !this.enableSomeElse){
      this.userform.controls['mobileNumber'].disable();
      this.userform.controls['email'].disable();
    }
    this.lastSlide = false;
    this.formValid = true;
    if(this.slideIndex == 1){
      this.firstSlide = true;
      this.slideIndex = 1;
    }
  }

 
  incrementAdultCount(){ 
    if(this.total_pax_allowed_now > 0){
        if((((this.numberOfAdults+1) + this.numberOfChildren) <= this.total_pax_allowed_now) && ((this.numberOfAdults+1) + this.numberOfChildren) <= AppVariables.max_pax){
          this.numberOfAdults++
        }else{
          this.toasterService.pop('error','',  'Maximum Participant Reached!');
    
        }

    }else{
       if(((this.numberOfAdults+1) + this.numberOfChildren) <= AppVariables.max_pax){
          this.numberOfAdults++
        }else{
          this.toasterService.pop('error','',  'Maximum Participant Reached!');
    
        }

    }
    
  }
  decrementAdultCount(){
    if(this.numberOfAdults > 1){
      this.numberOfAdults--
    }
  }

  incrementChildCount(){
    if(this.total_pax_allowed_now > 0){
      if((this.numberOfAdults + (this.numberOfChildren+1)) <= this.total_pax_allowed_now && ((this.numberOfAdults+1) + this.numberOfChildren) <= AppVariables.max_pax){
        this.numberOfChildren++
      }else{
        this.toasterService.pop('error','',  'Maximum Participant Reached!');
      }

    }else{
      if(((this.numberOfAdults+1) + this.numberOfChildren) <= AppVariables.max_pax){
        this.numberOfChildren++
      }else{
        this.toasterService.pop('error','',  'Maximum Participant Reached!');
  
      }
      

    }
   
  }

  decrementChildCount(){
    if(this.numberOfChildren > 0){
      this.numberOfChildren--
    }
  }

  checkLogin(){
    if(this.restApi.isTokenExists== true){
      this.resetFirstSlideform();
      this.formValid = false;
      $("#registerModal").modal({ backdrop: 'static' }, 'show');
    }else {
      localStorage.setItem("eventMasterCode",this.eventMasterCode)
      var url = '#/event/'+ this.eventMasterCode;
      //console.log("url",url)
      this.restApi.externalRedirect(url);
      //#Registered
    }
  }

  getOnlyOpenEvents(events){

    
    return events.filter(function(eachevent){
      let closed = eachevent.event_sessions.findIndex(function(x){
        let sessionDate = new Date(x.event_date)
        let now = new Date()
        return sessionDate < now
  
      });
      if(closed == -1 && eachevent.event_closed == false && eachevent.pax_limit_reached == false){

        return eachevent
      }

    })


  }

  getLocationData(locationCode){
    this.selectedEvent = "";
    this.lastSlide = true;
    this.numberOfChildren =  0;
    this.numberOfAdults = 1;
    this.userform.reset();
    this.userform.controls['mobileNumber'].enable();
    this.userform.controls['email'].enable();
    if(locationCode!=""){
      this.selectedEvent = this.eventsData.events.find(x => x.code === locationCode);
      console.log("this.selectedEvent::",this.selectedEvent)
      this.is_paid_event = this.selectedEvent.event_ispaid
      this.total_pax_allowed_now = this.selectedEvent.total_pax_allowed_now > 0 ? this.selectedEvent.total_pax_allowed_now : 0
      this.lastSlide = false;
      this.formValid = true;
    }else {
      this.formValid = false;
    }
  }

  getRegisterEventInfo(){
    if(this.enableUserForm && !this.enableSomeElse){
      this.restApi.getCustomer((data)=>{ 
        this.loggedInUserData = data

        var obj = {
          firstname : new FormControl(data.firstname,Validators.required),
          lastname : new FormControl(data.lastname),
          mobileNumber : new FormControl(data.mobile,[Validators.required, Validators.minLength(8),Validators.maxLength(13)]),
          email : new FormControl(data.email)
        }
        this.userform.controls['mobileNumber'].disable();
        this.userform.controls['email'].disable();
        this.userform = new FormGroup(obj);
        this.validateUpdateForm();
      });
    }
  }

  setUserFormEmpty(){
    this.userform.reset();
    this.userform.controls['mobileNumber'].enable();
    this.userform.controls['email'].enable();
  }

  numberOnly(event){
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  validateUpdateForm(){
    if(!this.userform.get('firstname').value || !this.userform.get('mobileNumber').value){
      this.formValid = false;
    }
    else if(this.userform.get('firstname').value == "" || this.userform.get('mobileNumber').value == "" || this.mobileValidator(this.userform.get('mobileNumber').value) ==  false){
      this.formValid = false;
    }    
    else {
      
      if(this.userform.get('email').value && this.userform.get('email').value != "" && this.emailValidator(this.userform.get('email').value) == false){
        this.formValid = false;
      }else{
        this.formValid = true;
      }
      
    }
  }

  addSomeoneElse(){
    console.log("next slideeee",this.enableSomeElse)
    if(this.enableSomeElse == true){
      console.log("in truee")
      this.enableSomeElse = false;
      this.enableUserForm = true
      this.getRegisterEventInfo();
    }else {
      this.enableSomeElse = true;
      this.enableUserForm = true
      this.setUserFormEmpty();
      this.validateUpdateForm()
    }
  }


  registerUserForEvent(){
      let userData  =  this.userform.value;
      let eventCode = this.selectedEvent.code;
      this.formValid = false
      this.lastSlide = true
      let mobile = userData.mobileNumber.toString()
      if(this.enableSomeElse){
        mobile = this.countrycode+userData.mobileNumber.toString()

      }
      
      let registerUserData = {
        "status_code": "psd4ew",
        "caring_user_code": "adas",
        "registered_by":this.loggedInUserData.mobile.toString(),
        "for_someone":this.enableSomeElse,
        "someelse_number":mobile,
        "no_of_adult_pax_registered": this.numberOfAdults,
        "no_of_child_pax_registered": this.numberOfChildren,
        "total_pax_registered": (this.numberOfAdults + this.numberOfChildren),
        "is_walkin": this.selectedEvent.direct_walkin_isallowed,
        "no_of_pax_attended" : 0,
        "transactionType":this.is_paid_event ? "paid":"free",
        "paxInfo": [
          {
            "first_name":userData.firstname,
            "last_name": userData.lastname ? userData.lastname:"",
            "mobile": mobile,
            "registered_by":this.loggedInUserData.mobile.toString(),
            "email": userData.email ? userData.email: this.loggedInUserData.email.toString() ,
            "is_child": false,
            "is_primary_registrant":  true,
            "is_someone" : this.enableSomeElse ? false : true 
          }
        ]
      }
      this.restApi.post(AppVariables.registerEventUrl+eventCode,registerUserData,(status,data)=>{
        if(status == true){
          if(data.result.code == 200){
            
            if(this.is_paid_event){
            let totalregistred = (this.numberOfAdults + this.numberOfChildren) == 0 ? 1 : (this.numberOfAdults + this.numberOfChildren)
            let amount = ((totalregistred)  * this.selectedEvent.event_price).toFixed(2).replace(".","").toString()
            this.genarateSignature(data.result.registration_code,amount).then(function(){
              console.log("calling i pay88")
              setTimeout(function(){
                this.formValid = true
                this.lastSlide = false
                $("#registerModal").modal('hide');
                $("#paymentId").submit();
              }, 1000)
            

            })

            }else{
              this.formValid = true
              this.lastSlide = false
              $("#registerModal").modal('hide');
              this._router.navigateByUrl('/dashboard/Registered');

            }
            
           }else {
            this.formValid = true
             this.lastSlide = false
             this.slideIndex = 3;
             this.toasterService.clear();
             this.toasterService.pop('error',data.result.code,  data.result.message);
             this.registerModalClose();
           }
        }else {
          this.formValid = true
           this.lastSlide = false
          this.slideIndex = 3;
          this.toasterService.pop('error','Registered Events',  'Something went wrong');
          this.registerModalClose();
        }
      });
    
  }

  
  getamount(eachRegisteredEvent){
    if(eachRegisteredEvent){
      let registreduser = (this.numberOfAdults + this.numberOfChildren) == 0 ? 1 : (this.numberOfAdults + this.numberOfChildren)
      let amount = (registreduser * eachRegisteredEvent.event_price).toFixed(2)
      return amount

    }
   
  }


  emailValidator(emailValue){
    let isValid = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/.test(emailValue); 
    if (isValid) {  
      return null;  
     } else {  
       return false;
     }
  }

  mobileValidator(mobilenumber){
    let isValid = /^[0-9]{8,13}$/.test(mobilenumber); 
    console.log("mobileee",isValid,mobilenumber)
    if (isValid) {  
      return null;  
     } else {  
       return false;
     }
  }

  getExstendsion(src){
    var fileExtension = src.split('.').pop();
    return fileExtension;
  }

  getOrganisersData(code){
    this.organisersData = this.eventsData.events.find(x => x.code === code);
    $("#myModal").modal({ backdrop: 'static' }, 'show');
  }

 
  registerModalClose(){
    this.selectedEvent = "";
    this.numberOfChildren =  0;
    this.numberOfAdults = 0;
    this.slideIndex = 1;
    this.firstSlide = true;
    this.resetFirstSlideform();
    this.userform.reset();
    this.userform.controls['mobileNumber'].enable();
    this.userform.controls['email'].enable();
    this.enableSomeElse = true;
    this.addSomeoneElse()
    $("#registerModal").modal('hide');
  }

  download(url) {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    var fileExtension = url.split('.').pop();
    let downloadName = 'download'+fileExtension;
    a.download = downloadName;
    a.click();
    document.body.removeChild(a);
  }

  open(content) {
    this.modalService.open(content);
  }
  openMdlReigister(content){
    this.modalService.open(content);
  }
}

