export const AppVariables = {
  userLocalStorage: "user",
  resourceAccessLocalStorage: "resourceAccessRaw",
  apiformat: "json",
  tableRowCount: 20,
  loginUrl:
    "https://connect.caring2u.com/#/?is_staff=0&client_code=ZhtsQLBxA3yy&redirect_url=http://event.caring2u.com/",
  upcomingEventsUrl:
    "https://eventapi.caring2u.com/api/v1/portal/upcomingevents",
  eachEventUrl: "https://eventapi.caring2u.com/api/v1/portal/eventmaster/get/",
  getCustomerUrl: "https://eventapi.caring2u.com/api/v1/customer/get",
  registerEventUrl:
    "https://eventapi.caring2u.com/api/v1/portal/customer/eventregistration/",
  getEvents: "https://eventapi.caring2u.com/api/v1/portal/customer/events",
  getCategoriesUrl:
    "https://eventapi.caring2u.com/api/v1/portal/eventcategory/get",
  getAttendedEvents:
    "https://eventapi.caring2u.com/api/v1/portal/customer/events/attended",
  checkeventRegistered:
    "https://eventapi.caring2u.com/api/v1/portal/customer/events/checkregistration",
  client_code: "ZhtsQLBxA3yy",
  auth_cookie_name: "token",
  auth_cookie_client_code: "client_code",
  auth_cookie_session_id: "SID",
  redirectUrl: "https://event.caring2u.com/",
  privateKey:
    "-----BEGIN RSA PRIVATE KEY-----\n" +
    "MIIEpAIBAAKCAQEA2dMcrdjPN3movKmMXhvVCYCpsx2VLzg0vI4B6tO9dtNaW364\n" +
    "Exb/PbfTVcecbGRxeztRR7wuytNilELIN3ss3CXVMZ6m0BzJRPfT+qD5u6bxNBPn\n" +
    "L67IcAiGJaDTDmqbsrQOElz31gepBBdsyC8yUF4SOESAbkK1Nu5BN02/vAhIFGT+\n" +
    "lyl36i+KvaYbYls+xqy/6GxH4+TE8wxMT6HuwUMIEk9Ro23dhZb4OOhgej8Pb+Yk\n" +
    "SfgA2ueuUAvXTp/97BUrHPWAcZfYsX9+He0K7PY13EnsMsFZcvB2hJC/ZRozpoyB\n" +
    "lidpncC3GDxcspyMxfgq6EJ1GtjxfIbiCt66mwIDAQABAoIBAChnHB6y5Fyp2D6G\n" +
    "RF8AUpecod1zU0Sa7E09GeXwuGfd9BicfJAt59IO0shKah1A1DvDWOQxZ4x/gP13\n" +
    "FaQU2NFMwyqc/twDOuHsBgmq6ieAY6UBPBSAGvWM3zUDi6JU6+RNuX52eBHfAd6c\n" +
    "abUH7BE0IbUGH7xT60DxGG+loDbECsK9YAtHwM2guohsz2Znf543cgt+9Jq8WBca\n" +
    "+iLqB24qbtRE9Dnqt2TCzwRD5YCLSS9Zli6rcwvz5lBX4AuuMIWCpeaAAqC8Dkzw\n" +
    "TlOujrdcI6+DDFF7QalRpJKqcQivNoxJ0AG/7ABqzyEiKHWxpFfAETBVC6Pz/8p6\n" +
    "wzDgeEECgYEA+J+M7Thz3w3BFxqgqUqIVmRRmXX8eDng0fTbtHTWaDtHRmDBf8t1\n" +
    "lGsOnooxOhYPnpMiEvllz67Cx99T0BCYVOjzDtqo1HDn+CZn/RMsc1v7wQB1ICil\n" +
    "g0zBbmIVNJDmTxW+e0W4gF6gJ6rK+QIT2kvHnsPugl909puBMyo6+vMCgYEA4Emg\n" +
    "fwjKZvaDVuMj8Y/i9GTnJgxRpttxe86y98Me3zjdvzGZxWZwYbZTtMN1/yCGgb0e\n" +
    "QSgaM2AOnRBN4UKdryr+07SnoBwCB5vicw8Ho3zel/5PtEXtRVTdyUlWULkSooiK\n" +
    "tkwN6oDGGRLxppPu1dP6nXyQArdazazTMHGJW7kCgYEAvOCZM257uKy+UEsOWw4g\n" +
    "XWGBDFCyKLPvtBGNB57rc+v0zeabmfZ7sPFT/eWGWFaCLzof3MJTMl/V4forthml\n" +
    "YHxIirRbJmlQERk8TurODiWHMKykTnYSp5kcKjuhWHnsPZCLUSM6c2tvyDLcWCfX\n" +
    "5TNEx7yZ6bmTJfYlRTWD8TkCgYEAm+cx8/u1TZrfMt9SldAxrzYQYqc/fp+dI7VL\n" +
    "O+PaqsmJgiRFQizxw0oMp2MSu1Pti3Nc+2eXxsxnfA/gQsuediuJ1LkHp8iwNH/M\n" +
    "CbSR17ettrNkzeephX73Z7pdkGEFLguBeDQnnDEFAlrjtKmx49qkoyH5rJmtObh/\n" +
    "WHYjR6kCgYBEqIn1hiCzovJtr0012yR1e3FLOa7CtJku9uMeoolb+KeRfHsK8pyd\n" +
    "Gi1e3KvfDJTsQ6VeyCzBzv9pY7Mmy1Y68CpYgj9QhtC5Du9I+p9t0BWQ0xqdwd/k\n" +
    "DLUCpYlCdSGB60evwG3fdrRb2O64x8lTp/8frJ7QUxQsac7M592Y3w==\n" +
    "-----END RSA PRIVATE KEY-----",
  sample_token:
    "eyJhbGciOiJSU0EtT0FFUCIsImVuYyI6IkExMjhDQkMtSFMyNTYifQ.BuowTSUmitNFVyiz3AjafNjVn5ePt90libdNcr78C8zyqFmil53dPriDzRM46Df-ongaiJdaeD-gJuv7QyAXDQd2EHw5360fIl8S-f_v_CWE5E9emJy0KlqkDqwt_3I-QUQLc12IcGtbmlPSgXgXOxgr_ceN5tcKqWXDfJiyP_fD_UUezbiHsV_37wYjzfzKqiTUVgx5vmE2Ouh0KNtYiFZ6zhHdIl4pRoKYcVl98D0vddKzSxBllrnXqHhNHwa4ejo5TscgEspcGiRfT8Ww8lE0YLIRH2GdLvdJcfmSKunWpklrj86cm9OyzQRyldv3pKidStlpd-oc_nrWRTzW_w.PnCCO8sFBtaioDtWXBFigw.iX3bnGSK23-SqGamG8xxZ6i-1pnluokUM8hG-eFgdeNf4aHuOb4__JljhMcaqnZMJb4LaYfdhxqTuuRA5qPaWAnt1iRVp1mSLdri1yE5nLApZ0ugxrc-uGAgBofkzCyg.TPzMm6o3rt7Ex2cREJkwKw",
  max_pax: 10,
  max_childrens: 10,
  item_per_page: 10,
  responsePage: "https://event.caring2u.com/response.php",
  Ipay88code: "M20248",
  Ipay88key: "puvyJY15Ki",
  backendUrl: "https://event.caring2u.com/response_backend.php",
};
