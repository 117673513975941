import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import {ToasterModule, ToasterService} from 'angular2-toaster';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SearchFilterPipe } from './filter.pipe';
import {NgxPrintModule} from 'ngx-print';
import { MomentModule } from 'ngx-moment';
import { AgmCoreModule } from '@agm/core';
import { CarouselModule } from 'ngx-bootstrap';


import { AppComponent } from './app.component';
import { routes } from './router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EventDetailsComponent } from './event-details/event-details.component';
import { PaymentGatewayComponent } from './payment-gateway/payment-gateway.component';
import { FooterComponent } from './footer/footer.component';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    EventDetailsComponent,
    PaymentGatewayComponent,
    FooterComponent,
    //SearchFilterPipe 
  ],
  imports: [
    BrowserModule,
    routes,
    FormsModule,
    HttpClientModule,
    NgbModule,
    ReactiveFormsModule,
    ToasterModule.forRoot(),
    CarouselModule.forRoot(),
    BrowserAnimationsModule,
    Ng2SearchPipeModule,
    NgxPaginationModule,
    NgxPrintModule,
    MomentModule,
    NgMultiSelectDropDownModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBFgkTkR5EiGhA54IFYwzoBO5pNv-2J9i0'
      /* apiKey is required, unless you are a 
      premium customer, in which case you can 
      use clientId 
      */
    })
  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
